<template>
    <div>
        <div :class="$style.subbar" v-if="instance">
            <div class="dF aC mb-0">
                <ul :class="$style.breadcrumbs" class="mr-4 mt-4" v-if="false">
                    <li :class="$style.breadcrumb" class="text-dark-purple">
                        {{ instance.name }}
                    </li>
                    <li
                        class="text-purple"
                        v-if="plotCategory && typeof plotCategory === 'object'"
                    >
                        {{ plotCategory.name }}
                    </li>
                </ul>

                <div
                    :class="$style.breadcrumbs"
                    style="align-items: center; display: block !important"
                >
                    <a-breadcrumb>
                        <a-breadcrumb-item>{{
                            instance.name
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item>{{
                            selectedAppInitial
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item
                            style="color: var(--orange)"
                            v-if="plotCategory"
                            >{{ plotCategory.name }}</a-breadcrumb-item
                        >
                    </a-breadcrumb>
                </div>

                <div :class="$style.amount" class="ml-auto d-none d-sm-flex">
                    <a-auto-complete
                        style="width: 300px"
                        placeholder="Search for a marker..."
                        :dropdown-match-select-width="false"
                        :dropdown-style="{ width: '300px' }"
                        option-label-prop="value"
                        @search="handleSearch"
                        class="mr-4"
                        size="large"
                    >
                        <template slot="dataSource">
                            <a-select-option
                                @click="optionClicked(marker)"
                                v-for="(marker, markerI) in searchResult"
                                :key="marker + markerI"
                                :value="
                                    marker.name +
                                    ', ' +
                                    marker.formatted_address +
                                    `${markerI != 0 ? markerI : ''}`
                                "
                            >
                                <div>
                                    <div>
                                        <strong>{{ marker.name }}</strong>
                                    </div>
                                    <small class="text-sm">{{
                                        marker.formatted_address &&
                                        marker.formatted_address != ""
                                            ? marker.formatted_address
                                            : ""
                                    }}</small>
                                </div>
                            </a-select-option>
                        </template>
                        <a-input>
                            <a-icon
                                style="stroke: #000; stroke-width: 34"
                                slot="prefix"
                                type="search"
                            />
                        </a-input>
                    </a-auto-complete>

                    <a-button
                        icon="setting"
                        class="mr-4"
						ghost
						type="primary"
                        @click="editDev"
                        size="large"
                    >
                        EDIT MAP
                    </a-button>

                    <a-dropdown class="add-amenity-dropdown">
                        <a-button
                            type="primary"
                            icon="plus"
							ghost
                            size="large"
                        >
                            ADD NEW MARKER
                        </a-button>
                        <template slot="overlay">
                            <a-menu>
                                <a-menu-item key="0" @click="addAmenityMarker">
                                    Amenity Marker
                                </a-menu-item>
                                <a-menu-item
                                    key="1"
                                    @click="
                                        $store.commit(
                                            'showAddAmenity',
                                            'marker---manual'
                                        )
                                    "
                                >
                                    Highlight Marker
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>

                    <a-button
                        size="large"
                        type="primary"
                        icon="plus"
                        class="ml-4"
                        @click="
                            $store.commit('showAddAmenity', 'category---manual')
                        "
                    >
                        ADD NEW CATEGORY
                    </a-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getAppIcon } from "bh-mod";
export default {
    data() {
        return {
            searchResult: [],
            previousMarker: "",
        };
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "AMC"
            );
        },
        searchItems() {
            return this.$store.state.markers;
        },
        instance() {
            return this.$store.state.instance;
        },
        currentCategory() {
            return this.$store.getters.currentCategory;
        },
        plotCategory() {
            return this.$store.state.amenityCategories[
                this.$store.state.plotCategory
            ];
        },
        theApp() {
            return this.$store.state.theApp || {};
        },
        appInitial() {
            let appinit;
            if (this.theApp.name == "Amenity Map") {
                appinit = "AMC";
            }
            return appinit;
        },
        mapMarkers() {
            return this.$store.state.appData.data.markers;
        },
        amenityCategories() {
            let { __pinnedMarkers, ...restCat } =
                this.$store.state.amenityCategories;
            console.log("AMENITY CATEGORIES EXCLUDING HIGHLIGHT", restCat);
            return Object.values(restCat);
            // return this.$store.state.amenityCategories
        },
    },
    methods: {
        addAmenityMarker() {
            console.log("Adding amenity marker...");
            if (this.amenityCategories.length == 0) {
                this.$message.error(
                    "No categories found. Please add a category first."
                );
                return;
            } else {
                this.$store.commit("showAddAmenity", "marker---google");
            }
        },
        mapInfo(marker, action) {
            if (!marker.markerOBJ) return null;

            if (action === "open") marker.markerOBJ.openInfoWindow();
            else marker.markerOBJ.closeInfoWindow();
        },
        optionClicked(mark) {
            console.log("OPTION CLICKED", mark);
            let id = mark.id;
            this.$store.commit("SELECT_MARKER", { type: "open", id: id });
            console.log("opening", id);
            if (this.previousMarker != "")
                this.mapInfo(this.mapMarkers[this.previousMarker], "close");
            setTimeout(() => {
                this.mapInfo(this.mapMarkers[id], "open");
            }, 500);
            this.previousMarker = id;
        },
        handleSearch(input) {
            if (input?.length > 0) {
                this.searchResult = Object.values(this.mapMarkers).filter(
                    ({ name, categoryId }) => {
                        name = name?.toLowerCase();
                        input = input?.toLowerCase();
                        return (
                            name?.includes(input) &&
                            categoryId != "null" &&
                            categoryId != null
                        );
                    }
                );
            } else {
                return (this.searchResult = []);
            }
        },
        getAppIcon,
        editDev() {
            this.$store.commit("editCategory", {
                id: "__dev",
                name: "Edit Map",
                icon: this.$store.state.appData.logo,
                isStatic: true,
                appLocation: true,
            });
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style src="bh-mod/styles/filemanager/files.css">
</style>
<style>
.secondary-edit-button.ant-btn {
    background-color: #f7f5f9;
    border: 1px solid var(--orange);
    color: var(--orange);
}
.secondary-edit-button.ant-btn:hover {
    background-color: var(--orange);
    color: #fff;
}
</style>
<style scoped>
.ant-dropdown-menu-item:hover {
    color: var(--orange);
}
.ant-dropdown-menu-item-disabled:hover {
    color: inherit !important;
}
</style>
